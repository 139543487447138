import React from 'react';

const PrivacyPolicy = () => (
    <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
            At Albion Online Profit Calculating Hub, we are committed to protecting your privacy. This Privacy Policy outlines the types of data we collect, how we use it, and your rights regarding this information.
        </p>
        <h2>1. Information Collection</h2>
        <p>
            We collect minimal data related to the performance and usage of our app for improvement purposes. This includes anonymous usage statistics and analytics to enhance your experience.
        </p>
        <h2>2. Google AdSense</h2>
        <p>
            This site uses Google AdSense to display ads. Google may use cookies to serve ads based on your prior visits to this and other websites.
        </p>
        <h2>3. Your Choices</h2>
        <p>
            You may disable cookies through your browser settings. However, doing so may affect your experience on our site.
        </p>
        <p>
            For questions regarding this policy, please contact us.
        </p>
    </div>
);

export default PrivacyPolicy;
