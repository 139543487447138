import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';

// Assuming you have an SVG or image for the "Soon" icon
const SoonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="16" x2="12" y2="12" />
        <line x1="12" y1="8" x2="12" y2="8" />
    </svg>
);


// Function to generate the image URL based on ingredient name and quality
const parseIngredientDetails = (ingredientDetails) => {
    const ingredients = ingredientDetails.slice(1, -1).split(/\), \(/).map(item => {
        const cleanedItem = item.replace(/[\(\)'"]/g, '').split(', ');
        const ingredientName = cleanedItem[0].trim();
        const imageUrl = `https://render.albiononline.com/v1/item/${ingredientName}.png?count=1&quality=1`;

        return {
            ingredientName: ingredientName,
            imageUrl: imageUrl,
            city: cleanedItem[1].trim(),
            price: cleanedItem[2].trim(),
            totalCost: cleanedItem[3].trim(),
        };
    });

    return ingredients;
};

const parseRecipeIdWithImage = (recipeId) => {
    const imageUrl = `https://render.albiononline.com/v1/item/${recipeId}.png?count=1&quality=1`;
    return {
        recipeName: recipeId,
        imageUrl: imageUrl
    };
};

// HamburgerMenu Component
const HamburgerMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="hamburger-menu">
            <button onClick={toggleMenu} className="hamburger-button">
                ☰
            </button>
            {menuOpen && (
                <div className="dropdown-menu">
                    <Link to="/" className="menu-link" onClick={() => setMenuOpen(false)}>Cooking Calculator</Link>
                    <Link to="/about-us" className="menu-link" onClick={() => setMenuOpen(false)}>About Us|Contact</Link>
                    <Link to="/privacy-policy" className="menu-link" onClick={() => setMenuOpen(false)}>Privacy Policy</Link>
                </div>
            )}
        </div>
    );
};

// Header Component
const Header = ({ activeCalculator, setActiveCalculator, selectedRegion, setSelectedRegion }) => {
    const handleRegionChange = (e) => {
        const selectedRegion = e.target.value;
        setSelectedRegion(selectedRegion); // Update the region in the parent state

        // Update the URL with the selected region without reloading the page
        const newUrl = new URL(window.location);
        newUrl.searchParams.set('region', selectedRegion);
        window.history.pushState({}, '', newUrl);
    };

    return (
        <div className="header">
            <h1 className="header-title">Albion Online Profit Calculating Hub</h1>
            <nav className="header-nav">
                {/* <button onClick={() => setActiveCalculator('cooking')}>Cooking</button>
                <button className="disabled-link" title="Under Construction" onClick={(e) => e.preventDefault()} disabled>
                    <SoonIcon className="disabled-icon" />
                    <span className="soon-text">Refining</span>
                </button>
                <button className="disabled-link" title="Under Construction" onClick={(e) => e.preventDefault()} disabled>
                    <SoonIcon className="disabled-icon" />
                    <span className="soon-text">Crafting</span>
                </button> */}
                Region:
                <select 
                    onChange={handleRegionChange} 
                    value={selectedRegion} 
                    className="region-selector"
                >
                    <option value="eu">EU</option>
                    <option value="us">US</option>
                    <option value="as">AS</option>
                </select>
                <HamburgerMenu />
            </nav>
        </div>
    );
};




// Floating AdSense bar
const AdSenseBar = () => {
    useEffect(() => {
        const adsenseScript = document.createElement('script');
        adsenseScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        adsenseScript.async = true;
        adsenseScript.setAttribute('data-ad-client', 'ca-pub-7144955910404530');
        document.body.appendChild(adsenseScript);

        return () => {
            document.body.removeChild(adsenseScript);
        };
    }, []);

    return (
        <div className="adsense-bar">
            <ins className="adsbygoogle"
                style={{ display: 'block', textAlign: 'center', height: '90px' }}
                data-ad-format="fluid"
                data-ad-layout-key="-gw-3+1f-3d+2z"
                data-ad-client="ca-pub-7144955910404530"
                data-ad-slot="1383268461"></ins>
            <center>Ad panel</center>
        </div>
    );
};

const Footer = () => (
    <footer className="footer">
        <p>&copy; 2024 Albion Profitability Hub. All rights reserved.</p>
        <p>This project is built on top of <a href="https://www.albion-online-data.com/" target="_blank" rel="noopener noreferrer">Albion Online Data Project</a>.</p>
        <p><a href="/privacy-policy">Privacy Policy</a></p>
    </footer>
);



const ProfitableResults = ({ selectedRegion }) => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        // Fetch data based on the selected region
        fetch(`https://albion-profit-hub.eu/api/profitable_results?region=${selectedRegion}`)
            .then(response => response.json())
            .then(data => setResults(data))
            .catch(error => console.error('Error fetching profitable results:', error));
    }, [selectedRegion]); // Listen for changes in selectedRegion

    return (
        <div className="table-container">
            <h1 className="page-title">Profitable Cooking Results - {selectedRegion}</h1>
            {results.length === 0 ? (
                <p className="no-results-message">Nothing profitable.</p> // Message when no results are found
            ) : (
            <table className="styled-table">
                <thead>
                    <tr>
                        <th>Recipe</th>
                        <th>City<br /><span className="small-text">(Where to sell)</span></th>
                        <th>Last Min Sell Price</th>
                        <th>Cost per Recipe Unit<br/><span className="small-text">(With 15.2% RRR)</span></th>
                        <th>Avg Sell Price <br/><span className="small-text">(2 Days)</span></th>
                        <th>Ingredient Details<br/><span className="small-text">(City, price per unit)</span></th>
                        <th>Total Ingredients Cost</th>
                        <th>Items Sold <br/><span className="small-text">(2 Days)</span></th>
                        <th>Usage Fee<br/><span className="small-text">(Already calculated in profit/loss)</span></th>
                        <th>Profit/Loss per Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map(result => (
                        <tr key={result.id}>
                            <td>
                                {(() => {
                                    const parsedRecipe = parseRecipeIdWithImage(result.recipe_id);
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={parsedRecipe.imageUrl} alt={parsedRecipe.recipeName} title={parsedRecipe.recipeName}
                                                style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                                            <span>{result.recipe_name}</span>
                                        </div>
                                    );
                                })()}
                            </td>
                            <td>{result.recipe_city}</td>
                            <td>{result.recipe_sell_price}</td>
                            <td>{result.cost_per_recipe}</td>
                            <td>{result.avg_price}</td>
                            <td>
                                {parseIngredientDetails(result.ingredient_details).map((ingredient, index) => (
                                    <div key={index} className="ingredient-row">
                                        <img src={ingredient.imageUrl} alt={ingredient.ingredientName} title={ingredient.ingredientName}
                                            className="ingredient-image" />
                                        <div>{ingredient.city}, {ingredient.price}</div>
                                    </div>
                                ))}
                            </td>
                            <td>{result.total_ingredient_cost}</td>
                            <td>{result.items_sold_2days}</td>
                            <td>{result.usage_fee}</td>
                            <td className={result.profit_loss > 0 ? 'profit-positive' : 'profit-negative'}>
                                {result.profit_loss}
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    );
};

// Same City Calculator
const SameCityResults = ({ selectedRegion }) => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        // Fetch data based on the selected region
        fetch(`https://albion-profit-hub.eu/api/profitable_results_same_city?region=${selectedRegion}`)
            .then(response => response.json())
            .then(data => setResults(data))
            .catch(error => console.error('Error fetching same city results:', error));
    }, [selectedRegion]); // Listen for changes in selectedRegion

    return (
        <div className="table-container">
            <h1 className="page-title">Single City Cooking Results - {selectedRegion}</h1>
            {results.length === 0 ? (
                <p className="no-results-message">Nothing profitable.</p> // Message when no results are found
            ) : (
            <table className="styled-table">
                <thead>
                    <tr>
                        <th>Recipe</th>
                        <th>City<br /><span className="small-text">(Where to sell)</span></th>
                        <th>Last Min Sell Price</th>
                        <th>Cost per Recipe Unit <br/><span className="small-text">(With 15.2% RRR)</span></th>
                        <th>Avg Sell Price <br/><span className="small-text">(2 Days)</span></th>
                        <th>Ingredient Details <br/><span className="small-text">(City, price per unit)</span></th>
                        <th>Total Ingredients Cost</th>
                        <th>Items Sold <br/><span className="small-text">(2 Days)</span></th>
                        <th>Usage Fee<br/><span className="small-text">(Already calculated in profit/loss)</span></th>
                        <th>Profit/Loss per Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map(result => (
                        <tr key={result.id}>
                            <td>
                                {(() => {
                                    const parsedRecipe = parseRecipeIdWithImage(result.recipe_id);
                                    return (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={parsedRecipe.imageUrl} alt={parsedRecipe.recipeName} title={parsedRecipe.recipeName}
                                                style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                                            <span>{result.recipe_name}</span>
                                        </div>
                                    );
                                })()}
                            </td>
                            <td>{result.recipe_city}</td>
                            <td>{result.recipe_sell_price}</td>
                            <td>{result.cost_per_recipe}</td>
                            <td>{result.avg_price}</td>
                            <td>
                                {parseIngredientDetails(result.ingredient_details).map((ingredient, index) => (
                                    <div key={index} className="ingredient-row">
                                        <img src={ingredient.imageUrl} alt={ingredient.ingredientName} title={ingredient.ingredientName}
                                            className="ingredient-image" />
                                        <div>{ingredient.city}, {ingredient.price}</div>
                                    </div>
                                ))}
                            </td>
                            <td>{result.total_ingredient_cost}</td>
                            <td>{result.items_sold_2days}</td>
                            <td>{result.usage_fee}</td>
                            <td className={result.profit_loss > 0 ? 'profit-positive' : 'profit-negative'}>
                                {result.profit_loss}
                            </td>

                            
                            
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    );
};


// Calculator Panel
const CalculatorPanel = ({ activeTab, setActiveTab }) => (
    <div className="tab-buttons">
        <button onClick={() => setActiveTab('profitable')} className={activeTab === 'profitable' ? 'active' : ''}>Profitable Results</button>
        <button onClick={() => setActiveTab('sameCity')} className={activeTab === 'sameCity' ? 'active' : ''}>Single City Results</button>
    </div>
);

const CalculatorContainer = ({ activeCalculator, activeTab, setActiveTab, selectedRegion }) => {
    if (activeCalculator === 'cooking') {
        return (
            <>
                <CalculatorPanel activeTab={activeTab} setActiveTab={setActiveTab} />
                {activeTab === 'profitable' ? 
                    <ProfitableResults selectedRegion={selectedRegion} /> : 
                    <SameCityResults selectedRegion={selectedRegion} />
                }
            </>
        );
    }
    return null;
};

const App = () => {
    const [activeCalculator, setActiveCalculator] = useState('cooking');
    const [activeTab, setActiveTab] = useState('profitable');
    const [selectedRegion, setSelectedRegion] = useState('eu'); // Default region

    return (
        <Router>
            <div className="app-container">
                <Header 
                    activeCalculator={activeCalculator}
                    setActiveCalculator={setActiveCalculator}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                />
                <Routes>
                    <Route path="/" element={<CalculatorContainer 
                        activeCalculator={activeCalculator} 
                        activeTab={activeTab}
                        setActiveTab={setActiveTab} 
                        selectedRegion={selectedRegion}
                    />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
                <AdSenseBar />
                <Footer />
            </div>
        </Router>
    );
};


export default App;
