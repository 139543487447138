import React from 'react';

const AboutUs = () => (
    <div className="about-us">
        <h1>About Us</h1>
        <p>
            Welcome to Albion Online Profit Calculating Hub! Our mission is to provide accurate and up-to-date profitability information for Albion Online players. Whether you're into cooking, refining, or crafting, our calculators are designed to help you maximize profits in game by leveraging Albion Online Data.
        </p>
        <h2>Project Overview</h2>
        <p>
            This project started as a tool to help players in Albion Online identify profitable recipes and regions for item sales. Using data from Albion Online Data, our platform calculates optimal pricing and profit estimates.
        </p>
        <h2>Our Vision</h2>
        <p>
            We aim to make the complex economy of Albion Online more accessible and rewarding for players by providing intuitive and efficient tools.
        </p>
        <p>
            Thank you for choosing Albion Online Profit Calculating Hub! We hope our platform enhances your experience in Albion Online.
        </p>
                <h2>Contact information</h2>

        <p>
           For any information regarding of calculation process, used data or your privacy or another questions you can contact us via email l[dot]malinik[at]gmail[dot].com
        </p>
    </div>
);

export default AboutUs;
